import React from 'react';

const CalculatorAside = () => (
  <div className="Tabs">
    <div className="Tabs__nav">
      <div className="Tabs__nav__item" data-tab-name="features">格式与特点</div>
    </div>
    <div className="Tabs__content">
      <div className="Tabs__content__item" data-tab-content="features">
        <ul>
          <li>大约<strong>275单词/页</strong></li>
          <li>所有格式（APA，MLA，Harvard，Chicago/Turabian）</li>
          <li>字体：12号 Arial/Times New Roman</li>
          <li>单或双行空距</li>
          <li>免费参考书目页</li>
          <li>免费标题页</li>
          <li><a rel="nofollow" href="https://www.customwritings.com/progressive-delivery.html">准时交稿</a></li>
        </ul>
      </div>
    </div>
  </div>
);

export default CalculatorAside;
